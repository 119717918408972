import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Api from '../utils/request/http';

export default function HomeRepairBox() {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [phoneTypeLst, setPhoneTypeLst] = React.useState([]);
    const phoneIdMapName = React.useRef({});

    const [repairTypeLst, setRepairTypeLst] = React.useState([]);
    const repairIdMapName = React.useRef({});

    const [formFields, setFormFields] = React.useState([{ id: 1, repairType: '', phoneType: '' , repairName: '', phoneName: ''}]);

    const handleChange = (id, field, value) => {
        // 设置对应的id的值和repairName和phoneName
        const updatedFields = formFields.map((formField) => {
          if (formField.id === id) {
            formField =  { ...formField, [field]: value, };
            if (field === 'phoneType') {
              formField.phoneName = phoneIdMapName.current[value]
              // 当切换 phoneType 时，清空 repairType
              return {
                ...formField,
                    [field]: value,
                    phoneName: phoneIdMapName.current[value],
                    repairType: '', // 重置 repairType
                    repairName: '' // 重置 repairName
                };
            } else if (field === 'repairType') {
              formField.repairName = repairIdMapName.current[value]
            }
          }
          return formField;
        });

        setFormFields(updatedFields);
        // setAllData((prevData) => {
        //     const updatedRepairs = { ...prevData };
        //     updatedRepairs.repairs = updatedFields
            
        //     return updatedRepairs;
        // });
    }

    const fetchPhoneRepair = () => {
        Api.get('/customer/phoneRepair', {})
          .then((data) => {
            // setShowLoader(false)
            if (data.code !== 1000) {
              enqueueSnackbar(data.msg,  {variant: 'error'})
            } else {
              
              setPhoneTypeLst(data.data.phoneTypeArr);
              data.data.phoneTypeList.forEach(item => {
                phoneIdMapName.current[item.id] = item.name
              });
              
              setRepairTypeLst(data.data.repairType);
              data.data.repairTypeList.forEach(item => {
                repairIdMapName.current[item.id] = item.name
              });
              // console.log('phoneRepair2',componentDat, setComponentDat)
            //   setPageDat({ ...pageDat, phoneRepairDat: data.data });
            }
          })
          .catch((error) => {
            // setShowLoader(false)
            console.error('Error:', error);
            enqueueSnackbar('Network error',  {variant: 'error'})
          });
      };

    const handleQuickRepair = () => {
        console.log('formFields', formFields)
        if (!formFields[0].phoneType || !formFields[0].repairType) {
            enqueueSnackbar('Please select correct phone type and repair type',  {variant: 'error'})
            return;
        }
        var allData = {cart: {}, repairs: [], imgs: [], imgPath:[]};
        allData.repairs = formFields;
        console.log('repair box allData', allData)
        sessionStorage.setItem('repair_alldata', JSON.stringify(allData));
        sessionStorage.setItem('repair_step', 0)
        // Navigate to repair page
        navigate('/Repair');
    };

    React.useEffect(() => {
        // if (pageDat.phoneRepairDat) {
        //   setPhoneTypeLst(pageDat.phoneRepairDat.phoneTypeArr);
        //   setRepairTypeLst(pageDat.phoneRepairDat.repairType);
        //   // setFormData({ ...formData, ...allData });
        //   if (allData.repairs.length > 0) {
        //     setFormFields(allData.repairs);
        //   }
        //   setShowLoader(false)
        //   return;
        // }
        fetchPhoneRepair();
        
        // console.log('component', componentDat)
        
    
      }, []);

    return <div style={{
        position: 'absolute',
        left: '12%',
        top: '38%',
        transform: 'translateY(-50%)',
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '300px',
        overflowX: 'hidden' // 添加这行
    }}>
        <form onSubmit={(e) => {
            e.preventDefault();
            handleQuickRepair();
        }}>
            <h3 style={{ marginBottom: '0px', color: '#333' }}>Repair Pricing</h3>
            {formFields.map((field, index) => (
            <Grid container style={{ marginBottom: '20px' }} key={field.id}>
                <Grid item xs={12} sm={12} >
                <FormControl variant="standard" sx={{ 
                    m: 1, 
                    width: '100%',
                    '& .MuiSelect-select': {
                        paddingRight: '24px', // 为下拉箭头预留空间
                        fontSize: '0.85rem'
                    }
                    }}>
                    <InputLabel id="demo-simple-select-standard-label" required>Phone type</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={field.phoneType ?? ''}
                        name='phoneType'
                        onChange={(e) => handleChange(field.id, 'phoneType', e.target.value)}
                        label="phoneType"
                        renderValue={(selected) => {
                            const selectedItem = phoneTypeLst.find(item => item.id === selected);
                            return selectedItem ? selectedItem.name.replace(/&nbsp;/g, '').trim() : '';
                        }}
                        MenuProps={{
                        PaperProps: {
                            sx: {
                            maxHeight: 300,
                            '& .MuiMenuItem-root': {
                                padding: '6px 16px',
                            }
                            }
                        },
                        // 防止滚动条闪烁
                        disableScrollLock: true
                        }}
                    >
                {phoneTypeLst.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                    <div key={item.id} dangerouslySetInnerHTML={{ __html: item.name }} />
                </MenuItem>
                ))}
                
            </Select>
            
            </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} >
                <FormControl variant="standard" sx={{ 
                    m: 1, 
                    width: '100%',
                    '& .MuiSelect-select': {
                        paddingRight: '24px', // 为下拉箭头预留空间
                        fontSize: '0.85rem'
                    }
                    }}>
                    <InputLabel id="demo-simple-select-standard-label" required>Repair type</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={field.repairType ?? ''}
                        name='repairType'
                        onChange={(e) => handleChange(field.id, 'repairType', e.target.value)}
                        label="phoneType"
                        MenuProps={{
                        PaperProps: {
                            sx: {
                            maxHeight: 300,
                            '& .MuiMenuItem-root': {
                                padding: '6px 16px'
                            }
                            }
                        },
                        // 防止滚动条闪烁
                        disableScrollLock: true
                        }}
                    >
                    {repairTypeLst[field.phoneType] && repairTypeLst[field.phoneType].map((item) => (
                        <MenuItem value={item.repair_id} key={item.repair_id}>{item.repair_name}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
            ))}
            <Button
                variant="contained"
                color="primary"
                onClick={handleQuickRepair}
                style={{ height: '39px', width: '100%', backgroundColor: '#29abe2'}} // 设置按钮高度
            >
                Get a free repair quote
            </Button>
        </form>
    </div>
}